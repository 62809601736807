import NewEditEducacaoInclusivaPage from "@/Pages/EducacaoInclusiva/NewEditEducacaoInclusivaPage.vue";

import FichaDiarioAtendimento from "@/Pages/EducacaoInclusiva/FichaDiarioAtendimento.vue";

import FichaDesenvolvimentoBimestral from "@/Pages/EducacaoInclusiva/FichaDesenvolvimentoBimestral.vue";

import NewEditFichaDiarioAtendimento from "@/Pages/EducacaoInclusiva/NewEditFichaDiarioAtendimento.vue";

export default [
  {
    path: "/educacao-inclusiva/create/:matricula_id",
    name: "educacaoInclusiva.create",
    component: NewEditEducacaoInclusivaPage,
    props: { editing: false },
  },
  {
    path: "/educacao-inclusiva/edit/:matricula_id",
    name: "educacaoInclusiva.edit",
    component: NewEditEducacaoInclusivaPage,
    props: { editing: true },
  },
  {
    path: "/educacao-inclusiva/ficha-desen-bimestral/:matricula_id/:gestaoDeAulasId",
    name: "educacaoInclusiva.fichaDesenvolvimentoBimestral",
    component: FichaDesenvolvimentoBimestral,
  },
  {
    path: "/educacao-inclusiva/ficha-diario-atend/:matricula_id/:gestaoDeAulasId",
    name: "educacaoInclusiva.fichaDiarioAtendimento",
    component: FichaDiarioAtendimento,
  },
  {
    path: "/educacao-inclusiva/diario-atendimento/create/:matricula_id/:gestaoDeAulasId",
    name: "educacaoInclusivaDiario.create",
    component: NewEditFichaDiarioAtendimento,
    props: { editing: false },
  },
  {
    path: "/educacao-inclusiva/:gestaoDeAulasId/diario-atendimento/:id/edit/:matricula_id",
    name: "educacaoInclusivaDiario.edit",
    component: NewEditFichaDiarioAtendimento,
    props: { editing: true },
  },
];
