<template>
  <v-row justify="center">
    <v-dialog v-model="dialogAvisoMobile" width="60%">
      <v-alert class="clickable" border="left" elevation="3" type="warning"
        >A imagem só será registrada após você clicar em enviar dados, no final da aba</v-alert
      >
    </v-dialog>
    <v-dialog v-model="dialog" persistent width="600px">
      <v-card>
        <v-card-title class="text-h5"> Adaptar Imagem</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="typeImage === 'imagem-aluno'"
            color="red darken-1"
            text
            @click="cancelarAluno()"
          >
            Cancelar
          </v-btn>
          <v-btn v-else color="red darken-1" text @click="cancelarCarteira()"> Cancelar </v-btn>
          <v-btn color="green darken-1" text @click="cortarImagem(coordinates, img)">
            Salvar
          </v-btn>
        </v-card-actions>
        <cropper
          v-if="typeImage === 'carteira-vacina'"
          style="width: 100%; height: 600px"
          class="cropper"
          :src="img"
          :stencil-props="{
            aspectRatio: 20 / 12,
          }"
          @change="change"
        />
        <cropper
          v-else
          style="width: 100%; height: 600px"
          class="cropper"
          :src="img"
          :stencil-props="{
            aspectRatio: 12 / 12,
          }"
          @change="change"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "EModalCortarImagem",
  props: {
    dialogProp: {
      type: Boolean,
    },
    img: {
      type: String,
    },
    typeImage: {
      type: String,
    },
  },
  components: { Cropper },
  data() {
    return {
      dialogAvisoMobile: false,
      coordinates: null,
      dialog: this.dialogProp,
    };
  },
  watch: {
    dialogProp(novoValor) {
      this.dialog = novoValor;
    },
  },
  methods: {
    change({ coordinates }) {
      this.coordinates = coordinates;
    },
    async cortarImagem(tamanhos, img) {
      this.dialogAvisoMobile = true;
      if (this.typeImage === "carteira-vacina") {
        const data = await this.$services.alunosService.cortarCarteiraVacina(tamanhos, img);
        this.atualizarImgCarteira(data);
        this.atualizarImgCarteira(data.nameImgAdaptada, data.singleName);
        try {
          const alunoId = this.$route.params.aluno_id;
          await this.$services.alunosService.atualizarImagemCarteira(alunoId, data.singleName);
          this.$toast.success("Imagem da carteira atualizada com sucesso!");
        } catch (error) {
          this.$toast.error("Falha ao atualizar a imagem da carteira.");
        }
      }

      if (this.typeImage === "imagem-aluno") {
        const data = await this.$services.alunosService.cortarImageAluno(tamanhos, img);
        this.atualizarImgAluno(data.nameImgAdaptada, data.singleName);
        try {
          const alunoId = this.$route.params.aluno_id;
          await this.$services.alunosService.atualizarImagemAluno(alunoId, data.singleName);
          this.$toast.success("Imagem do aluno atualizada com sucesso!");
        } catch (error) {
          this.$toast.error("Falha ao atualizar a imagem do aluno.");
        }
      }
    },
    atualizarImgCarteira(urlCompleta, nomeImagem) {
      this.dialog = false;
      this.$emit("atualizar-imagem-carteira", { url: urlCompleta, name: nomeImagem });
    },
    atualizarImgAluno(urlCompleta, nomeImagem) {
      this.dialog = false;
      this.$emit("atualizar-imagem-aluno", { url: urlCompleta, name: nomeImagem });
    },
    cancelarAluno() {
      // this.$emit("atualizar-imagem-aluno", { url: null, name: null });
      this.dialog = false;
    },
    cancelarCarteira() {
      // this.$emit("atualizar-imagem-carteira", { url: null, name: null });
      this.dialog = false;
    },
  },
};
</script>
