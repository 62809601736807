<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => this.$router.go(-1)"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>RELATÓRIO DESCRITIVO DE AVALIAÇÃO E ACOMPANHAMENTO BIMESTRAL</v-card-title>
          <v-card-text>
            <h4>Turma: {{ gestao?.turma ? gestao?.turma.descricao : "- - -" }}</h4>
            <h4>Aluno: {{ matricula?.aluno ? matricula?.aluno.nomecompleto : "- - -" }}</h4>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title>Professores (diários)</v-card-title>
          <v-card-text>
            <div>Professor(a): {{ gestao?.professor ? gestao?.professor.nome : "- - -" }}</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card-text>
      <v-row class="mt-5">
        <v-select
          :items="bimestres"
          item-text="descricao"
          item-value="id"
          v-model="bimestreSelecionado"
          return-object
          label="Selecione um bimestre"
          solo
        ></v-select>
      </v-row>
    </v-card-text>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-card>
          <v-card-text>
            <v-row
              v-for="(item, index) in $constants.RelatorioDescritivoDeAvaliaçãoEAcompanhamento"
              :key="index"
            >
              <v-col cols="12">
                <ValidationProvider :name="item.titulo" rules="required" v-slot="{ errors }">
                  <v-card-title>
                    <strong class="mr-2">{{ item.titulo }}</strong>
                    <v-icon> {{ item.icone }}</v-icon>
                  </v-card-title>

                  <v-card-text v-if="item.descricao">
                    {{ item.descricao }}
                  </v-card-text>
                  <v-textarea
                    solo
                    dense
                    v-model="form[item.form]"
                    :error-messages="errors"
                    class="pa-4"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-col cols="12" class="mt-4">
          <v-row>
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
          </v-row>
        </v-col>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  mounted() {
    this.loadBasicData();
  },
  watch: {
    bimestreSelecionado() {
      this.loadBasicData();
    },
  },
  data() {
    return {
      submittingForm: false,
      gestao: null,
      form: {
        periodo_do_plano: "",
        duracao_do_atendimento: "",
        frequencia: "",
        tipo_atendimento: "",
      },
      painel: null,
      matricula: null,
      gestao_de_aulas: [],
      bimestres: [
        { id: 0, descricao: "1º Bimestre" },
        { id: 1, descricao: "2º Bimestre" },
        { id: 2, descricao: "3º Bimestre" },
        { id: 3, descricao: "4º Bimestre" },
      ],
      bimestreSelecionado: {
        id: 0,
      },
    };
  },
  methods: {
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { matricula_id } = this.$route.params;
        const { gestaoDeAulasId } = this.$route.params;
        this.form.matricula_id = matricula_id;
        this.form.gestao_id = gestaoDeAulasId;
        this.form.bimestre_id = this.bimestreSelecionado.id;
        this.form.matricula_id = this.matricula.id;
        this.$toast.info("Relatório salvo com sucesso!");
        await this.$services.educacaoInclusivaService.lancarFichaDeDesenvolvimentoBimestral(
          this.form
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$router.push({ name: "relatorios.visualizarDiarioAee" });
    },

    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { matricula_id } = this.$route.params;
        const { gestaoDeAulasId } = this.$route.params;
        const response =
          await this.$services.educacaoInclusivaService.carregarFichaDeDesenvolvimentoBimestral(
            matricula_id,
            gestaoDeAulasId,
            this.bimestreSelecionado.id
          );
        this.form = response.ficha ? response.ficha : {};
        this.matricula = response.matricula;
        this.gestao = response.gestao;
        this.gestao_de_aulas = response.gestao_de_aulas;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
