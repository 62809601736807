<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'aulas',
                  params: { gestaoDeAulaId: $route.params.gestaoDeAulaId },
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Aula
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green" class="mb-5 d-flex" outlined>
          <v-card-text>
            <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
            <template v-if="!gestaoDeAula.is_polivalencia"> </template>
            <template v-else>
              <h4>Disciplinas:</h4>
              <div class="container">
                <div class="row">
                  <div v-for="(item, index) in gestaoDeAula.disciplinas" :key="index">
                    <v-chip class="ml-2" x-small>{{ item.descricao }} </v-chip>
                  </div>
                </div>
              </div>
            </template>
            <h4>
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[NÃO INFORMADO]" }}
            </h4>
            <h4>
              Turno:
              {{
                gestaoDeAula.turma.turno ? gestaoDeAula.turma.turno.descricao : "[NÃO INFORMADO]"
              }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ValidationObserver v-if="etapas" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="6" v-if="etapas?.length">
            <ValidationProvider name="Etapa" rules="required" v-slot="{ errors }">
              <e-label>Etapas</e-label>

              <e-autocomplete
                v-if="editing"
                disabled
                :items="etapas"
                :return-object="false"
                :error-messages="errors"
                :item-text="
                  (item) =>
                    `${item.descricao} - Início: ${dateFormatConvert(
                      item.periodo_inicial
                    )} Fim: ${dateFormatConvert(item.periodo_final)}`
                "
                :item-value="(item) => item.id"
                v-model="etapaSelecionada"
                @change="() => verificarEtapaSelecionada()"
                label="Selecione um Etapa"
                dense
                solo
              />

              <e-autocomplete
                v-else
                :items="etapas"
                :return-object="false"
                :error-messages="errors"
                :item-text="
                  (item) =>
                    `${item.descricao} - Início: ${dateFormatConvert(
                      item.periodo_inicial
                    )} Fim: ${dateFormatConvert(item.periodo_final)}`
                "
                :item-value="(item) => item.id"
                v-model="etapaSelecionada"
                @change="() => verificarEtapaSelecionada()"
                label="Selecione um Etapa"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col>
            <v-btn
              v-if="etapaBloqueada == true && etapaPendente == false"
              style="box-shadow: none"
              color="success"
              class="text-none mt-4"
              @click="() => criarPedido(etapaSelecionada)"
              >Solicitar Autorização</v-btn
            >
          </v-col>
        </v-row>
        <div v-if="etapaSelecionada && etapaBloqueada == false">
          <v-row>
            <v-col class="pt-0 pb-0" cols="6">
              <ValidationProvider name="Tipo de Aula" rules="required" v-slot="{ errors }">
                <e-label>Tipo de Aula</e-label>
                <e-autocomplete
                  :items="$constants.tiposDeAulas"
                  :return-object="false"
                  :error-messages="errors"
                  v-model="aulaSelecionada.tipo_de_aula"
                  @change="() => verificaTipoDaAula()"
                  label="Selecione um tipo de aula"
                  dense
                  solo
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row v-if="mostrarCampos">
            <v-col class="pt-0 pb-0" cols="6">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="data"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <e-label>Selecione uma data</e-label>
                  <v-text-field
                    v-model="aulaSelecionada.data"
                    persistent-hint
                    :disabled="aulaSelecionada.tipo_de_aula ? false : true"
                    label="Escolha uma data"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    solo
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data"
                  scrollable
                  :min="inicioPeriodoEtapa"
                  :max="fimPeriodoEtapa"
                  :allowed-dates="allowedDates"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(data)"> OK </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <!-- <v-col class="pt-0 pb-0" v-if="aulaSelecionada.tipo_de_aula !== 'Aula Remota'" cols="6">
                <ValidationProvider name="Horário da aula" rules="required" v-slot="{ errors }">
                  <e-label>Selecione um horário</e-label>
                  <v-alert v-if="!gestaoDeAula.turma.turno_id" class="pa-1" type="error" border="left"
                    >Essa turma está sem turno cadastrado, por favor, edite a turma e selecione um
                    turno para ela.</v-alert
                  >
                  <e-autocomplete
                    v-else
                    :disabled="aulaSelecionada.data ? false : true"
                    :items="horarios"
                    :error-messages="errors"
                    label="Selecione uma opção"
                    :item-text="(item) => item.descricao"
                    v-model="horarioSelecionado"
                    return-object
                    dense
                    @change="() => pesquisaAulas()"
                  />
                </ValidationProvider>
              </v-col> -->

            <v-col cols="12">
              <div cols="12" v-if="!editing">
                <div v-if="gestaoDeAula.is_polivalencia">
                  <e-label>Selecione as Disciplinas dessa Aula:</e-label>
                  <div class="container">
                    <div class="row">
                      <v-checkbox
                        class="ml-2"
                        v-for="(item, index) in gestaoDeAula.disciplinas"
                        :key="index"
                        v-model="disciplinasMarcadas"
                        :label="item.descricao"
                        :value="item.id"
                      ></v-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <v-col cols="12" v-else>
                <div v-if="aulaSelecionada.disciplinas?.length">
                  <div class="row mb-2">
                    <e-label>Disciplinas dessa Aula</e-label>
                  </div>
                  <div class="container">
                    <div class="row">
                      <div v-for="(item, index) in aulaSelecionada.disciplinas" :key="index">
                        <v-chip x-small>{{ getNomeDisciplina(item) }} </v-chip>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <template v-if="!gestaoDeAula.is_polivalencia">
                <e-label>Conteúdo</e-label>
                <v-textarea
                  solo
                  dense
                  v-model="aulaSelecionada.conteudo"
                  :error-messages="errors"
                />
              </template>

              <template v-else>
                <e-label>Conteúdos</e-label>
                <div v-if="!editing">
                  <v-card v-for="item in arrayConteudos" :key="item" class="mx-auto my-1 col-12">
                    <strong>{{ item.descricao }}: </strong>
                    <v-textarea solo dense v-model="conteudos[item.id]" />
                    <strong>Horário de {{ item.descricao }}: </strong>
                    <ValidationProvider name="horario" rules="required" v-slot="{ errors }">
                      <e-autocomplete
                        :disabled="aulaSelecionada.data ? false : true"
                        :items="horarios"
                        multiple
                        label="Selecione uma opção"
                        :item-text="(item) => item.descricao"
                        v-model="horarios_selecionados_extras[item.id]"
                        return-object
                        dense
                        @change="
                          ($event) => {
                            verifyHorariosSelectExtra($event, item.id, $event);
                          }
                        "
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-card>
                </div>
                <div
                  v-else-if="editing && JSON.parse(aulaSelecionada?.horarios_extras).length >= 0"
                >
                  <v-card
                    v-for="(item, index) in arrayConteudos"
                    :key="index"
                    class="mx-auto my-1 col-12"
                  >
                    <strong>{{ getNomeDisciplina(item.id) }}</strong>
                    <v-textarea
                      solo
                      dense
                      :value="item.content"
                      @change="editarConteudoPoli(index, $event)"
                    />

                    <div>
                      <div class="mb-5">
                        <ul v-if="horariosDefinidos.find((x) => x.id === item.id)?.array">
                          <div
                            v-for="(item, ind) in horariosDefinidos.find((x) => x.id === item.id)
                              ?.array"
                            :key="ind"
                          >
                            <li>{{ getObjHorario(item) }}</li>
                          </div>
                        </ul>
                        <v-btn @click="editarHorario = index">Alterar Horário</v-btn>
                      </div>
                    </div>

                    <template v-if="editarHorario === index">
                      <ValidationProvider name="horario" rules="required" v-slot="{ errors }">
                        <e-autocomplete
                          :disabled="aulaSelecionada.data ? false : true"
                          :items="horarios"
                          multiple
                          label="Selecione"
                          :item-text="(item) => item.descricao"
                          v-model="horarios_selecionados_extras[item.id]"
                          return-object
                          dense
                          @change="
                            ($event) => {
                              verifyHorariosSelectExtra($event, item.id, $event);
                            }
                          "
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </template>
                  </v-card>
                </div>
              </template>

              <ValidationProvider name="Metodologia" rules="required" v-slot="{ errors }">
                <e-label>Metodologia</e-label>
                <v-textarea
                  solo
                  dense
                  v-model="aulaSelecionada.metodologia"
                  :error-messages="errors"
                />
              </ValidationProvider>

              <!-- <div v-if="outrosCampos">
                  <e-label>Objetivos</e-label>
                  <v-textarea solo dense v-model="aulaSelecionada.objetivos_ou_habilidades" />
                </div>

                <div v-if="outrosCampos">
                  <e-label>Avaliação</e-label>
                  <v-textarea solo dense v-model="aulaSelecionada.avaliacao" />
                </div>

                <div v-if="outrosCampos">
                  <e-label>Atividade</e-label>
                  <v-textarea solo dense v-model="aulaSelecionada.atividade_classe" />
                </div>

                <div v-if="outrosCampos">
                  <e-label>Recursos</e-label>
                  <v-textarea solo dense v-model="aulaSelecionada.recursos" />
                </div> -->

              <v-btn
                color="primary"
                :disabled="submittingForm"
                :loading="submittingForm"
                type="submit"
                >Enviar Dados</v-btn
              >
              <v-btn
                v-if="editing && outrosCampos"
                class="ml-3"
                color="success"
                @click="dialog.duplicar = true"
                >Duplicar Aula</v-btn
              >
              <v-btn
                v-if="editing && outrosCampos"
                class="ml-3"
                color="success"
                @click="dialog.exportar = true"
                >Exportar Aula</v-btn
              >
            </v-col>
          </v-row>
        </div>
        <v-row v-if="etapas?.length === 0 && etapaBloqueada == false">
          <v-col cols="12">
            <v-alert type="warning" border="left">
              Não existem etapas disponíveis para a data atual
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="etapaBloqueada == true && etapaPendente == false">
          <v-col cols="12">
            <v-alert :class="avisoAnime" type="warning" border="left">
              Atenção essa etapa está bloqueada, para lançar aulas você deve solicitar uma
              autorização, clicando no botão acima
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="etapaPendente == true">
          <v-col cols="12">
            <v-alert :class="avisoAnime" type="warning" border="left">
              Aguarde, já existe um pedido de autorização para essa etapa e estamos analisando.
            </v-alert>
            <v-btn class="success" @click="verPedidos">Acompanhe seus pedidos</v-btn>
          </v-col>
        </v-row>

        <div v-if="etapas?.length > 0 && etapaBloqueada == false && etapaSelecionada">
          <v-row v-if="!aulaSelecionada.tipo_de_aula || !aulaSelecionada.data">
            <v-col cols="12" v-if="aulaSelecionada.tipo_de_aula !== 'Aula Remota'">
              <v-alert type="warning" border="left"> Por favor, preencha todos os campos! </v-alert>
            </v-col>
          </v-row>
        </div>
      </form>

      <v-bottom-sheet v-model="aulasEmConflitos" scrollable persistent>
        <v-sheet class="text-center pa-md-8 mx-lg-auto" height="auto">
          <!-- <v-btn color="error" dark small @click="aulasEmConflitos = !aulasEmConflitos">
              Fechar
            </v-btn> -->

          <h2 class="mt-4">Horário em Conflito com aulas já cadastradas!</h2>
          <h2 class="mt-4 mb-2">{{ horarioComConflito?.descricao }}</h2>
          <v-btn color="error" dark small @click="aulasEmConflitos = !aulasEmConflitos">
            Resolver Conflito
          </v-btn>
        </v-sheet>
      </v-bottom-sheet>
    </ValidationObserver>
    <v-alert text border="left" color="orange" icon="warning" class="text-caption" v-else
      >Atenção, essa turma ainda não foi vinculada a um circuito, para fazer isso você tem que ir em
      Turmas->Editar e Selecione o Circuito desejado, caso seja um professor aguarde o responsável
      realizar esse procedimento.</v-alert
    >
    <e-modal-duplicar-aula
      v-if="aulaSelecionada.disciplinas"
      :submittingForm="submittingForm"
      :dialog="dialog.duplicar"
      :gestaoDeAulas="aulaSelecionada"
      :form="{ ...aulaSelecionada }"
      :horarios="horarios"
      :disciplinas="aulaSelecionada.disciplinas"
      @dialogChange="dialog.duplicar = $event"
    >
    </e-modal-duplicar-aula>
    <e-modal-duplicar-aula
      v-else
      :submittingForm="submittingForm"
      :dialog="dialog.duplicar"
      :gestaoDeAulas="aulaSelecionada"
      :form="{ ...aulaSelecionada }"
      :horarios="horarios"
      @dialogChange="dialog.duplicar = $event"
    >
    </e-modal-duplicar-aula>
    <e-modal-exportar-aula
      :submittingForm="submittingForm"
      :dialog="dialog.exportar"
      :form="{ ...aulaSelecionada }"
      @dialogChange="dialog.exportar = $event"
      :horarios="horarios"
    >
    </e-modal-exportar-aula>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";
import { dateFormatParam } from "../../plugins/filters";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    aula_id: {
      type: Number,
      require: true,
    },
  },
  computed: {
    tipo_de_aula() {
      return this.aulaSelecionada.tipo_de_aula;
    },
  },
  mounted() {
    this.loadData();
    if (this.editing) {
      this.mostrarCampos = true;
      this.outrosCampos = true;
    }
  },
  data() {
    return {
      diasPermitidosParaCriarAula: [0, 1, 2, 3, 4, 5, 6],

      arrayIdsHorarios: [],
      horarioComConflito: null,
      disciplinaComConflito: null,
      checkHorarioExtra: [],
      array_horarios_extras: [],
      horarios_selecionados_extras: [],
      conteudos: [],
      horarios_selecionados: [],
      arrayConteudos: [],
      text: "",
      textColor: "#000000",
      disciplinasMarcadas: [],
      avisoAnime: "",
      etapaPendente: false,
      etapaBloqueada: false,
      idsEtapasDisponivels: [],
      idsEtapasPendentes: [],
      autorizacao: [],
      mostrarEtapas: false,
      fimPeriodoEtapa: null,
      inicioPeriodoEtapa: null,
      etapaSelecionada: null,
      etapas: null,
      turma: null,
      data: null,
      modal: false,
      aulasEmConflitos: false,
      aulasConflitadas: [],
      mostrarCampos: false,
      outrosCampos: false,
      submittingForm: false,
      form: {},
      aulaSelecionada: {
        data: null,
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      aulaDuplicada: {},
      gestaoDeAulas: null,
      dialog: {
        duplicar: false,
        exportar: false,
      },
      horarios: [],
      horarioSelecionado: {
        descricao: "Escolha um horario",
      },
      arrayConteudoEditar: [],
      arrayHorarios: [],
      arrayHorariosEditar: [],
      editarHorario: null,
      horariosAll: [],
      horariosDefinidos: [],
      arrayHorariosMarcados: [],
    };
  },
  watch: {
    horarios_selecionados_extras(old, novo) {},

    checkHorarioExtra(val) {},
    disciplinasMarcadas(val) {
      const arrayConvert = [];
      val.forEach((element) => {
        const valor = this.gestaoDeAula.disciplinas.filter((dado) => dado.id === element);
        arrayConvert.push(valor[0]);
      });
      this.atualizarTopicos(arrayConvert);
    },
    tipo_de_aula() {
      if (this.aulaSelecionada.tipo_de_aula === "Aula Remota") {
        this.aulaSelecionada.horario_inicial = null;
        this.aulaSelecionada.horario_final = null;
        this.horarioSelecionado = null;
      }
    },
    data(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", {
        timeZone: "UTC",
      }).format(new Date(value));
      this.aulaSelecionada.data = data_old.replaceAll("/", "/");
    },
  },
  methods: {
    getHorariosDaAula(aula) {
      const horariosFiltrados = [];

      const horariosDaAulaExtra = JSON.parse(aula?.horarios_extras);

      return horariosDaAulaExtra;
    },

    getArrayHorarios(array) {
      // const data =   array.forEach((idHorario) => {
      //         horariosFiltrados.push(this.horarios.filter((item) => item.id === idHorario)[0]);
      //       });
      // return data;
    },
    // verifyHorariosSelect(item, index) {
    //   let arrayDeObjetos = this.horarios_selecionados;
    //   arrayDeObjetos = arrayDeObjetos.map((objeto) => objeto.id);
    //   const filter = arrayDeObjetos.filter((it) => it === item.id);
    //   if (filter.length >= 2) {
    //     this.$toast.warn("Esse horário já esta sendo usado, escolha outro!");
    //   }
    // },
    valoresComuns(array1, array2) {
      return array1.filter((valor) => array2.includes(valor));
    },
    verifyHorariosSelectExtra(item, IDdisciplina, $event) {
      // this.arrayHorariosMarcados = [];

      // verifcar conflito antes de push

      if (this.array_horarios_extras.filter((it) => it.id === IDdisciplina)[0]) {
        this.array_horarios_extras.filter((it) => it.id === IDdisciplina)[0].array = [];
      }

      const disciplina = {
        id: IDdisciplina,
        array: [],
      };

      const filter = this.array_horarios_extras.filter((it) => it.id === IDdisciplina);
      if (filter.length < 1) {
        this.array_horarios_extras.push(disciplina);
      }

      const horariosDosMeusCampos = [];

      item.forEach((element) => {
        this.array_horarios_extras.filter((it) => it.id === IDdisciplina)[0].array.push(element.id);

        this.pesquisaAulas(element.id, IDdisciplina);

        const teste = this.array_horarios_extras.filter((it) => it.id !== IDdisciplina);
        const horariosDosOutrosCampos = [];
        if (teste) {
          teste.forEach((it) => {
            it.array.forEach((element) => {
              horariosDosOutrosCampos.push(element);
            });
          });
        }

        horariosDosMeusCampos.push(element.id);

        const conflitouLocal = this.valoresComuns(horariosDosOutrosCampos, horariosDosMeusCampos);

        if (conflitouLocal.length > 0) {
          this.$toast.warn("Esse horário está sendo usado, escolha outro!");
          // remover o horário selecionado
          this.horarios_selecionados_extras[IDdisciplina] = this.horarios_selecionados_extras[
            IDdisciplina
          ].filter((it) => it.id !== element.id);
          // remover do array que envia
        }
      });
    },
    allowedDates(val) {
      const array = [...this.diasPermitidosParaCriarAula];
      return array.includes(new Date(val).getDay());
    },
    removerValorDoArray(valor) {
      let horarios = this.horarios;
      horarios = horarios.filter((item) => item.id !== valor.id);
    },

    getNomeDisciplina(id) {
      const dis = this.gestaoDeAula.disciplinas.filter((obj) => obj.id === id);
      return dis[0].descricao;
    },
    getObjHorario(id) {
      if (id) {
        const dis = this.horarios.filter((obj) => obj.id === id);
        return dis[0].descricao;
      }
    },
    editarConteudoPoli(index, $event) {
      this.arrayConteudos[index] = $event;
    },
    editarHorarioPoli(index, $event) {
      this.arrayHorarios[index] = $event;
    },
    getConteudoPolivalencia() {
      this.aulaSelecionada.disciplinas.forEach((element, index) => {
        this.arrayConteudoEditar.push(
          JSON.parse(this.aulaSelecionada.conteudo_polivalencia)[index]
        );
      });

      this.arrayConteudoEditar.forEach((element, index) => {
        const obj = {
          id: this.aulaSelecionada.disciplinas[index],
          content: element,
        };
        this.arrayConteudos.push(obj);
      });
    },
    getHorariosPolivalencia(id) {
      return this.horarios.filter((item) => item.id === id);
    },
    atualizarTopicos(array) {
      this.arrayConteudos = [];
      array.forEach((element) => {
        this.arrayConteudos.push(element);
      });
    },
    updateText(event) {
      this.text = event.target.innerHTML;
    },
    executeCommand(command) {
      document.execCommand(command, false, null);
    },

    changeFont(size) {
      document.execCommand("fontSize", false, "7");
      const sel = window.getSelection();
      if (sel.rangeCount) {
        const range = sel.getRangeAt(0);
        const node = document.createElement("span");
        node.style.fontSize = size;
        range.surroundContents(node);
      }
    },
    changeStyle(style) {
      document.execCommand(style, false, null);
      const sel = window.getSelection();
      if (sel.rangeCount) {
        const range = sel.getRangeAt(0);
        const node = document.createElement("span");
        node.style.fontStyle = style === "italic" ? style : null;
        node.style.fontWeight = style === "bold" ? style : null;
        node.style.textDecoration = style === "underline" ? style : null;
        range.surroundContents(node);
      }
    },
    setTextColor() {
      this.executeCommand("foreColor", this.textColor);
    },
    animarAviso() {
      this.avisoAnime = "animate__animated animate__headShake";
      setTimeout(() => (this.avisoAnime = ""), 500);
    },
    dateFormatConvert(data) {
      return dateFormatParam(data);
    },
    verificarEtapaSelecionada() {
      this.animarAviso();
      this.inicioPeriodoEtapa = this.etapas
        .filter((d) => d.id === this.etapaSelecionada)
        .shift().periodo_inicial;
      this.inicioPeriodoEtapa = dateFormatParam(this.inicioPeriodoEtapa, "y-m-d");
      this.fimPeriodoEtapa = this.etapas
        .filter((d) => d.id === this.etapaSelecionada)
        .shift().periodo_final;
      this.fimPeriodoEtapa = dateFormatParam(this.fimPeriodoEtapa, "y-m-d");

      const etapaVerificar = this.etapas.filter((d) => d.id === this.etapaSelecionada).shift();

      this.verificarSeEtapaEstaDisp(etapaVerificar);
    },
    async pesquisaAulas(horarioId, disciplinaId) {
      const data_aula = this.aulaSelecionada.data.split("/").reverse().join("-");
      // /* eslint-disable */
      const { aula_id } = this.$route.params;
      const turma_id = this.gestaoDeAula.turma.id;
      if (!data_aula || !horarioId || !this.aulaSelecionada) return;

      const response = await this.$services.aulasService.pesquisarAulasEmConflitosPoli(
        data_aula,
        horarioId,
        turma_id,
        aula_id
      );

      if (response.data === 1) {
        // this.$toast.warn("Horário conflitante, removendo horário...");
        this.horarioComConflito = this.horarios.filter((item) => item.id === horarioId).shift();
        this.disciplinaComConflito = disciplinaId;

        this.horarios_selecionados_extras[disciplinaId].pop();

        this.aulasEmConflitos = true;
      }
    },

    async verificaTipoDaAula() {
      if (this.aulaSelecionada.tipo_de_aula === "Aula Remota" || this.editing) {
        this.getAula();
        this.mostrarCampos = true;
        this.outrosCampos = true;
        return;
      }
      this.mostrarCampos = true;
      this.outrosCampos = false;
    },
    async editarHorarioIndividual(index, obj) {
      const horariosAula = JSON.parse(this.aulaSelecionada.horarios);
      horariosAula[index] = obj.id;
      this.aulaSelecionada.data = this.aulaSelecionada.data.split("/").reverse().join("-");
      this.aulaSelecionada.horarios = JSON.stringify(horariosAula);

      await this.$services.aulasService.atualizar(this.aulaSelecionada);
      this.$toast.success("Horário editado com sucesso!");
      this.loadData();
      this.editarHorario = null;
    },
    async submitForm() {
      if (this.editing) {
        // conteudos
        const arrayParaEditar = this.arrayConteudos;
        arrayParaEditar.forEach((element, index) => {
          if (typeof element !== "string") {
            arrayParaEditar[index] = element.content;
          }
        });
        this.aulaSelecionada.conteudo_polivalencia = JSON.stringify(arrayParaEditar);

        this.aulaSelecionada.horarios_extras = JSON.stringify(this.array_horarios_extras);
      } else {
        if (this.conteudos.some((elemento) => elemento !== null)) {
          const arr = [];
          this.disciplinasMarcadas.forEach((element) => {
            arr.push(this.conteudos[element]);
          });

          this.aulaSelecionada.conteudo_polivalencia = JSON.stringify(arr);

          const horr = [];
          const horr_extras = [];
          this.horarios_selecionados.forEach((element, index) => {
            horr.push(element.id);
          });

          this.aulaSelecionada.horarios = JSON.stringify(horr);
          this.aulaSelecionada.horarios_extras = JSON.stringify(this.array_horarios_extras);

          // salvar no banco a tabela horarios extras
          // salvar com o array da dicilopma: ex-> [1] = [horario1]
        }

        this.aulaSelecionada.disciplinas = this.disciplinasMarcadas;
      }

      this.aulaSelecionada.horario_inicial = this.horarioSelecionado
        ? this.horarioSelecionado.inicio
        : null;
      this.aulaSelecionada.horario_final = this.horarioSelecionado
        ? this.horarioSelecionado.final
        : null;
      this.aulaSelecionada.horario_id = this.horarioSelecionado ? this.horarioSelecionado.id : null;
      this.aulaSelecionada.etapa_id = this.etapaSelecionada;

      this.submittingForm = true;
      try {
        if (this.editing) {
          this.aulaSelecionada.instrutorDisciplinaTurma_id = parseInt(
            this.$route.params.gestaoDeAulaId,
            10
          );
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("/").reverse().join("-");
          this.aulaSelecionada.etapa_id = this.aulaSelecionada.etapa_id.id;
          await this.$services.aulasService.atualizar(this.aulaSelecionada);
          this.$toast.success("Aula editada com sucesso!");
          this.$router.back();
        } else {
          if (!this.aulaSelecionada.conteudo_polivalencia) {
            this.$toast.warn("Adicione pelo menos algum campo de conteúdo!");
            this.submittingForm = false;
            return;
          }

          this.aulaSelecionada.instrutorDisciplinaTurma_id = parseInt(
            this.$route.params.gestaoDeAulaId,
            10
          );
          this.aulaSelecionada.disciplina_id = this.gestaoDeAula.disciplina_id;
          this.aulaSelecionada.turma_id = parseInt(this.gestaoDeAula.turma_id, 10);
          this.aulaSelecionada.instrutor_id = parseInt(this.gestaoDeAula.instrutor_id, 10);
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("/").reverse().join("-");
          await this.$services.aulasService.criar(this.aulaSelecionada);
          this.$toast.success("Aula cadastrada com sucesso!");
          this.$router.back();
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async getAula() {
      const { aula_id } = this.$route.params;
      const response = await this.$services.gestoesDeAulasService.listarAulasGestao(
        this.$route.params.gestaoDeAulaId
      );
      this.turma = response.payload.gestaoDeAula.turma;
      this.gestaoDeAula = response.payload.gestaoDeAula;
      this.horarios = response.payload.horarios.filter((horario) => {
        if (this.gestaoDeAula.is_polivalencia) {
          return horario.descricao;
        }
        return horario.descricao;
      });
      const aulaSelecionada = await this.$services.aulasService.getAula(parseInt(aula_id, 10));
      this.horarioSelecionado = aulaSelecionada.horario ?? null;

      this.text = aulaSelecionada.conteudo;
      if (this.gestaoDeAula.relacoesDiasHorarios) {
        this.diasPermitidosParaCriarAula = this.gestaoDeAula.relacoesDiasHorarios.map(
          (g) => g.dia.id
        );

        this.horarios = this.gestaoDeAula.relacoesDiasHorarios.map((g) => g.horario);
        this.horariosSabado = this.horarios;
        this.horariosSabado = this.horariosSabado.map((h) => {
          return {
            id: h.id,
            turno_id: h.turno_id,
            descricao: h.descricao.split(" - ")[0],
            final: h.final,
            inicio: h.inicio,
          };
        });
        this.horariosDoComponente =
          new Date(this.data).getDay() === 5 ? this.horariosSabado : this.horarios;
      }
      return aulaSelecionada;
    },
    async loadData() {
      try {
        this.$loaderService.open("Carregando dados...");
        this.aulaSelecionada = await this.getAula();
        if (this.turma.circuito_nota_id) {
          const response = await this.$services.etapasService.syncAllEtapasDisponiveis(
            this.turma.circuito_nota_id,
            this.$route.params.gestaoDeAulaId
          );
          this.etapas = response.etapas;
          this.idsEtapasDisponivels = response.disponiveis;

          this.etapas.forEach((element) => {
            this.verificarSeTemPedido(element.id);
          });

          if (this.editing) {
            this.getEtapa();
            this.horariosDefinidos = this.getHorariosDaAula(this.aulaSelecionada);
          }
        }
        if (this.aulaSelecionada.data) {
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("-").reverse().join("/");
        }
        this.$forceUpdate();

        if (this.editing) {
          this.getConteudoPolivalencia();
          this.getHorariosPolivalencia();
          this.array_horarios_extras = JSON.parse(this.aulaSelecionada.horarios_extras);
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    getEtapa() {
      let etapa = this.etapas.filter((d) => d.id === this.aulaSelecionada.etapa_id);
      etapa = etapa.shift();
      this.fimPeriodoEtapa = etapa.periodo_final;
      this.inicioPeriodoEtapa = etapa.periodo_inicial;
      this.etapaSelecionada = etapa;
    },
    criarPedido(etapaId) {
      this.$router.push({ name: "autorizacoes.create", params: { etapa: etapaId } });
    },
    verPedidos() {
      this.$router.push({ name: "autorizacoes" });
    },
    verificarSeEtapaEstaDisp(etapa) {
      if (this.idsEtapasDisponivels.includes(etapa.id)) {
        this.etapaBloqueada = false;
      } else {
        this.etapaBloqueada = true;
      }
      if (this.idsEtapasPendentes.includes(etapa.id)) {
        this.etapaPendente = true;
      } else {
        this.etapaPendente = false;
      }
    },
    async verificarSeTemPedido(etapaId) {
      const response = await this.$services.autorizacoesService.verificarPedidosDeferidos(etapaId);

      if (response.data.aprovados?.length) {
        this.idsEtapasDisponivels.push(etapaId);
      }

      if (response.data.pendentes?.length) {
        this.idsEtapasPendentes.push(etapaId);
      }
    },
    beforeDestroy() {
      this.text = this.$refs.editor.innerHTML;
    },
  },
};
</script>

<style scoped>
.dataInput {
  background-color: white;
  width: 100%;
  height: 35px;
  box-shadow: 2px 2px rgb(0, 0, 0, 0.1);
}
</style>
