<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.go(-1)" :disabled="false">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Gestão de Relatório
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-if="turma && aluno">
          <v-card-title>Relatório</v-card-title>
          <v-card-text>
            <h4>Turma: {{ turma.descricao }}</h4>
            <h4>Aluno: {{ aluno.nomecompleto }}</h4>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title>Professores (diários)</v-card-title>
          <v-card-text>
            <div v-for="diario in gestao_de_aulas" :key="diario.id">
              Professor(a): {{ diario?.professor?.nome }} | Disciplina:
              {{ diario.disciplina?.descricao }}
            </div>
          </v-card-text>
          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn class="caption ml-2" @click="show = !show" text
              >Legendas para auxiliar no lançamento do sistema BNCC</v-btn
            >

            <v-spacer></v-spacer>

            <v-btn icon @click="show = !show">
              <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>

              <v-card-text>
                <v-chip v-for="(legenda, i) in legendas" :key="i" class="ma-2" small>
                  <strong>{{ i }}</strong
                  >&nbsp;-&nbsp;{{ legenda }}
                </v-chip>
                <v-divider></v-divider>
                <v-chip v-for="(style, i) in styleClass" :key="i" class="ma-2" small :color="style">
                  <strong>{{ i }}</strong
                  >&nbsp;-&nbsp;lançamento(s)
                </v-chip>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-3">
      <template>
        <v-tabs v-model="tab_atual" background-color="primary" dark>
          <v-tab v-for="tab of tabs" :key="tabs.indexOf(tab)">{{ tab.descricao }}</v-tab>
        </v-tabs>

        <v-card-text>
          <v-simple-table class="elevation-1" v-show="tab_atual == 0">
            <template v-slot:default>
              <tbody v-for="parent of sistemaBNCC" :key="parent.id">
                <tr>
                  <td colspan="3" class="text-h6">{{ parent.descricao }}</td>
                </tr>
                <tr v-for="child in parent.children" :key="child.id" :class="changeColor(child)">
                  <td class="pr-4">
                    <span class="font-weight-medium">
                      {{ child.apelido }}
                    </span>
                    - {{ child.descricao }}
                  </td>
                  <td>
                    <v-select
                      class="mt-4"
                      :items="opcoes"
                      v-model="notas[child.id].nota_s1"
                      label="S1"
                      outlined
                      dense
                    ></v-select>
                  </td>
                  <td>
                    <v-select
                      class="mt-4"
                      :items="opcoes"
                      v-model="notas[child.id].nota_s2"
                      label="S2"
                      outlined
                      dense
                    ></v-select>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <!-- ABAS DE RESUMOS -->

          <!-- <v-simple-table class="elevation-1" v-show="tab_atual == 1">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td colspan="5" class="text-h6">
                    <v-textarea v-model="resumos.b1">
                      <template v-slot:label>
                        <div>Descrição bimestre 1 <small>(opcional)</small></div>
                      </template>
                    </v-textarea>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="elevation-1" v-show="tab_atual == 2">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td colspan="5" class="text-h6">
                    <v-textarea v-model="resumos.b2">
                      <template v-slot:label>
                        <div>Descrição bimestre 2 <small>(opcional)</small></div>
                      </template>
                    </v-textarea>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="elevation-1" v-show="tab_atual == 3">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td colspan="5" class="text-h6">
                    <v-textarea v-model="resumos.b3">
                      <template v-slot:label>
                        <div>Descrição bimestre 3 <small>(opcional)</small></div>
                      </template>
                    </v-textarea>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="elevation-1" v-show="tab_atual == 4">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td colspan="5" class="text-h6">
                    <v-textarea v-model="resumos.b4">
                      <template v-slot:label>
                        <div>Descrição bimestre 4 <small>(opcional)</small></div>
                      </template>
                    </v-textarea>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table> -->
        </v-card-text>
        <v-col cols="12">
          <v-btn color="success" :loading="promiseCount > 0" block @click="salvarNotas"
            >Enviar Relatório</v-btn
          >
        </v-col>
      </template>
    </v-card>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      legendas: null,
      opcoes: null,
      sistemaBNCC: null,
      notas: null,
      aluno: null,
      matricula: null,
      turma: null,
      gestao_de_aulas: null,
      promiseCount: 0,
      tab_atual: 0,
      resumos: {},
      styleClass: ["red lighten-4", "yellow accent-1", "light-green accent-1"],
      show: true,
      tabs: [
        {
          id: 0,
          descricao: "Sistema BNCC",
        },
        // {
        //   id: 1,
        //   descricao: "Resumo bimestre 1",
        // },
        // {
        //   id: 2,
        //   descricao: "Resumo bimestre 2",
        // },
        // {
        //   id: 3,
        //   descricao: "Resumo bimestre 3",
        // },
        // {
        //   id: 4,
        //   descricao: "Resumo bimestre 4",
        // },
      ],
    };
  },
  created() {
    this.loadBasicData();
  },
  methods: {
    changeColor(obj) {
      let countClass = 0;
      if (this.notas[obj.id].nota_s1) {
        countClass += 1;
      }
      if (this.notas[obj.id].nota_s2) {
        countClass += 1;
      }
      return this.styleClass[countClass];
    },
    async loadBasicData() {
      try {
        this.$loaderService.open("Carregando dados...");
        const payload = await this.$services.sistemaBNCCService.syncAll(
          parseInt(this.$route.params.matricula_id, 10)
        );

        this.sistemaBNCC = payload.perguntas;
        this.legendas = payload.sistema.sistema_b_n_c_c.legenda;
        this.opcoes = Object.keys(payload.sistema.sistema_b_n_c_c.opcoes);
        this.notas = payload.notas;
        this.aluno = payload.matricula.aluno;
        this.matricula = payload.matricula;
        this.turma = payload.matricula.turma;
        this.resumos = { ...payload.resumos, matricula_id: this.matricula.id };
        this.gestao_de_aulas = payload.matricula.turma.gestao_de_aulas;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async salvarNotas() {
      this.promiseCount += 1;
      try {
        await this.$services.sistemaBNCCService.salvarNotas(
          parseInt(this.$route.params.matricula_id, 10),
          { notas: this.notas, resumos: this.resumos }
        );
        this.$toast.info("Relatório Salvo...");
      } catch (error) {
        this.$handleError(error);
      }
      this.promiseCount -= 1;
      this.$router.back();
    },
  },
};
</script>

<style></style>
